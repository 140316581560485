import firebase from "firebase";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAJfie-xyltRyBKir-lqIbjS8ioROpwau0",
  authDomain: "walk-wing-production.firebaseapp.com",
  databaseURL: "https://walk-wing-production-default-rtdb.firebaseio.com",
  projectId: "walk-wing-production",
  storageBucket: "walk-wing-production.appspot.com",
  messagingSenderId: "667297011805",
  appId: "1:667297011805:web:dad74eaf302bc3b3bd55b4",
  measurementId: "G-RZF9MT11NE"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
