import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Grid, Typography, Avatar, Button, ToggleButtonGroup } from "@mui/material";
import Slider from "react-slick";
import "./slick.css";
import firebase from "../configs/firebase";
import { styled } from '@mui/material/styles';

export default function ButtonAppBar() {
  const [imgRestaurant, setImgRestaurant] = useState();
  const refRestaurant = firebase.database().ref("Restaurant");

  useEffect(() => {
    refRestaurant.once("value", (snapShot) => {
      const value = snapShot.val();
      // console.log(value);

      const imgRestaurantArray = [];
      for (let i in value) {
        // console.log(i);
        // console.log(value[i].img);
        imgRestaurantArray.push({ img: value[i].img, name: value[i].name })

        // var imgRestaurant = value[i].img
      }
      // console.log(imgRestaurantArray);
      setImgRestaurant(imgRestaurantArray);
      // var test = Object.keys(value).length;
      // console.log(a);
    });

  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", color: "blue" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", color: "red !important" }}
        onClick={onClick}
      />
    );
  }
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <React.Fragment>
      <Box id={'walkwing-partner'} sx={{ flexGrow: 1, background: "rgba(0,0,0,0.9)", color: "#53BB96" }}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 1,
            },
            justifyContent: "center",
            height: 750,
          }}
        >
          <Box sx={{ justifyContent: "center", display: "flex" }}>
            <Typography
              variant="h3"
              align="center"
              sx={{ fontWeight: "bold", marginTop: 20, }}
            >
              Wing Point ใช้ทำอะไรได้บ้าง ?
            </Typography>
          </Box>
          <Box sx={{ justifyContent: "center", display: "flex" }}>
            {/* <Typography variant="h4" sx={{ fontWeight: "bold", marginTop: 15 }}>
              เหรียญ Wng ใช้ทำอะไรได้บ้าง?
            </Typography> */}
            {/* {imgRestaurant?.map((test) => {
              console.log(test);
              return (
                <Avatar
                  alt="Remy Sharp"
                  src={test}
                  variant="square"
                // style={{ height: 71, width: "100%" }}
                />
              )
            })} */}

          </Box>
          <Grid container>
            <Grid item xs={12} sm={6} sx={{ textAlign: "left" }}>
              <Typography variant="h6" sx={{ margin: { xs: 2, md: 10 }, fontWeight: "bold" }}>
                WNG ใช้แลกซื้อหรือรับสิทธิ์ต่าง ๆ
                ภายในแอปพลิเคชันตามเงื่อนไขของร้านค้าที่ร่วมรายการได้ระบุไว้
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  marginBottom: 5,
                  fontWeight: "bold",
                }}
              >
                ร้านค้าที่เข้าร่วมรายการ
              </Typography>
              <Box
                sx={{ display: 'contents', textAlign: "center", mx: { xs: 0, sm: 15 }, width: '100%' }}
              >
                <Slider {...settings}>
                  {
                    imgRestaurant?.map((test) => {
                      return (
                        <Box>
                          <Button>
                            <Avatar src={test?.img} alt="Remy Sharp" sx={{ bgcolor: "#53BB96", height: 90, width: 90 }} />
                          </Button>
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontWeight: "bold",
                            }}
                          >
                            {test?.name}
                          </Typography>
                        </Box>
                      )
                    })
                  }
                </Slider>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
}
