import * as React from "react";
import { Grid, Box, Avatar, Button, Typography, Stack } from "@mui/material";

export default function ButtonAppBar() {
  return (
    <React.Fragment>
      <Box id={'walkwing-whatwalkwing'} sx={{ flexGrow: 1, background: "rgba(0,0,0,1)", color: "#53BB96" }}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 1,
            },
            justifyContent: "center",
          }}
        >
          <Box sx={{ justifyContent: "center", display: "flex" }}>
            <Typography
              align="center"
              variant="h3"
              sx={{ fontWeight: "bold", marginTop: 20, marginBottom: 5 }}
            >
              WalkWing ( วอร์ควิง ) คือ อะไร ?
            </Typography>
          </Box>
          <Box sx={{ px: { xs: 0, md: 40 } }}>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              WalkWing คือ แอปพลิเคชันที่พัฒนาโดย บริษัท สตรีมเซาท์เทคโนโลยี จำกัด
              ทางผู้พัฒนาได้เล็งเห็นประโยชน์ของการเดิน
              จึงได้เกิดไอเดียที่จะพัฒนาแอปพลิเคชันนี้ขึ้น
              เมื่อผู้ใช้ทำการติดตั้งพร้อมเข้าสู่ระบบ
              ทุกก้าวที่ผู้ใช้เดินจะถูกนำมาแปลงเป็นเหรียญภายในแอปพลิเคชันซึ่งเรียกว่า
              WNG สามารถนำเหรียญมาแลกซื้อหรือรับสิทธิ์กับร้านค้าต่าง ๆ
              ที่ร่วมรายการกับทางแอปพลิเคชัน
            </Typography>
          </Box>
          <Box sx={{ justifyContent: "center", display: "flex" }}>
            <Typography
              variant="h3"
              sx={{ fontWeight: "bold", marginTop: 5, marginBottom: 5 }}
            >
              รู้หรือไม่? ยิ่งคุณเดินมาก
            </Typography>
          </Box>
          <Grid sx={{ marginBottom: '60px' }} container>
            <Grid item xs={12} sm={4}>
              <Typography variant="h5" sx={{ textAlign: "center", paddingRight: 10, paddingLeft: 10, marginBottom: '60px' }}>
                1. ทุกๆ 1,000 ก้าว
                จะเผาผลาญแคลอรี่
                ได้ถึง 50 kCal
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h5" sx={{ textAlign: "center", paddingRight: 10, paddingLeft: 10, marginBottom: '60px' }}>
                2. ทุกๆ 100 ก้าว
                จะได้รับ Wing Point
                1 เหรียญ
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h5" sx={{ textAlign: "center", paddingRight: 10, paddingLeft: 10, marginBottom: '60px' }}>
                3. ทุกๆ 10,000 ก้าว ต่อวัน
                คุณจะสุขภาพดี
                แข็งแรง ดูอ่อนเยาว์
              </Typography>
            </Grid>
          </Grid>
          <Box id={'walkwing-howwalkwing'} sx={{ flexGrow: 1, background: "rgba(255,255,255,01)", color: "#53BB96", marginTop: '20px' }}>
            <Box sx={{ justifyContent: "center", display: "flex" }}>
              <Typography
                variant="h3"

                sx={{ fontWeight: "bold", marginTop: 20, marginBottom: 5 }}
              >
                วิธีการรับ Wing Point
              </Typography>
            </Box>
            <Grid sx={{ marginBottom: '60px', paddingLeft: { xs: "58px", md: '0' } }} container>
              <Grid sx={{ textAlign: "center", paddingRight: 10 }} item xs={12} sm={4}>
                <Box sx={{ width: '100%', height: '100%' }}>
                  <img
                    style={{ width: '92%' }}
                    src={'./images/step01-01.png'}
                    alt={'walkwing-step-01'}
                  />
                  <Typography variant="h5">
                    1. ดาวน์โหลดแอปพลิเคชัน WalkWing
                  </Typography>
                  <Stack
                    marginTop={'2px'}
                    direction={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{ marginLeft: 5 }}
                    spacing={1}
                  >
                    <Box width={'100%'} >
                      <Button component="a" href='https://play.google.com/store/apps/details?id=com.walkwing.app'>
                        <img
                          style={{ width: '70%' }}
                          src={'./images/android.png'}
                          alt={'walkwing-step-01'}
                        />
                      </Button>
                    </Box>
                    <Box width={'100%'} >
                      <Button component="a" href='https://apps.apple.com/th/app/walkwing/id1580362898?l=th'>
                        <img
                          style={{ width: '70%' }}
                          src={'./images/ios.png'}
                          alt={'walkwing-step-01'}
                        />
                      </Button>
                    </Box>
                  </Stack>
                  <Typography variant="h5">
                    และลงทะเบียนเข้าใช้งาน
                  </Typography>
                </Box>
              </Grid>
              <Grid sx={{ textAlign: "center", paddingRight: 10 }} item xs={12} sm={4}>
                <img
                  style={{ width: '100%' }}
                  src={'./images/step02-01.png'}
                  alt={'walkwing-step-01'}
                />
                <Typography variant="h5">
                  2. เดินหรือวิ่งไปพร้อมๆ
                  กับมือถือของคุณ
                  อย่าลืม! ยิ่งเดินมาก
                  ยิ่งได้ Wing Point มากนะ
                </Typography>
              </Grid>
              <Grid sx={{ textAlign: "center", paddingRight: 10 }} item xs={12} sm={4}>
                <img
                  style={{ width: '100%' }}
                  src={'./images/step03-01.png'}
                  alt={'walkwing-step-01'}
                />
                <Typography sx={{ marginTop: { xs: '0px', md: '25px' } }} variant="h5">
                  3. ก่อนเข้านอนกดเข้าแอป
                  เพื่อรับ Wing Point ตามจำนวน
                  ก้าวที่เราเดินได้ในวันนี้
                  และร่วมสนุกลุ้นรับรางวัล
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {/* <Grid container>
            <Grid item xs={6} sx={{ marginTop: 20, padding: 4 }}>
              <Typography
                variant="h6"
                sx={{ textAlign: "center", fontWeight: "bold" }}
              >
                WNG คืออะไร?
              </Typography>
              <Typography variant="h6" sx={{ margin: 5, textAlign: "left" }}>
                WNG คือเหรียญสะสมที่ได้จากแอปพลิเคชัน WalkWing
                ผ่านการทำกิจกรรมกับแอปพลิเคชัน เช่น การเดิน หรือ กิจกรรมที่
                WalkWing ได้จัดขึ้น
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ marginTop: 20, textAlign: "center" }}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  marginBottom: 5,
                }}
              >
                สามารถเก็บเหรียญ wng ได้อย่างไร?
              </Typography>
              <Grid container spacing="5" justifyContent="center">
                <Grid item>
                  <Button>
                    <Avatar
                      alt="Remy Sharp"
                      style={{ height: 120, width: 120 }}
                    />
                  </Button>
                  <Typography variant="h6">วิธีที่1</Typography>
                </Grid>
                <Grid item>
                  <Button>
                    <Avatar
                      alt="Remy Sharp"
                      style={{ height: 120, width: 120 }}
                    />
                  </Button>
                  <Typography variant="h6">วิธีที่2</Typography>
                </Grid>
                <Grid item>
                  <Button>
                    <Avatar
                      alt="Remy Sharp"
                      style={{ height: 120, width: 120 }}
                    />
                  </Button>
                  <Typography variant="h6">วิธีที่3</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}
        </Box>
      </Box>
    </React.Fragment>
  );
}
