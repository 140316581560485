import React, { useEffect, useState } from "react";
import {
  Grid,
  Toolbar,
  Typography,
  Avatar,
  Button,
  Box,
  AppBar,
  Paper,
  styled,
  Slide,
  useScrollTrigger,
  Stack,
  Card,
  CardContent,
  IconButton,
  CardMedia,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Container,
} from "@mui/material";

import firebase from "../configs/firebase";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import {
  Close as CloseIcon,
  Menu as MenuIcon
} from '@mui/icons-material'
import './homestyle.css'
import { typography } from "@mui/system";
export default function ButtonAppBar(props) {
  const [steps, setSteps] = useState();
  const [users, setUsers] = useState();
  const [kcals, setKcals] = useState();
  const [wngs, setWngs] = useState();
  const [show, setShow] = useState(false);
  const toggleMenu = () => setShow(!show);
  const refProfile = firebase.database().ref("Profile");

  // const [test, setTest] = useState();
  // const [a, setA] = useState();
  // const [imgRestaurant, setImgRestaurant] = useState();
  // const refRestaurant = firebase.database().ref("Restaurant");

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  useEffect(() => {
    refProfile.once("value", (snapShot) => {
      const value = snapShot.val();
      // console.log(value);
      var users = Object.keys(value).length;
      // console.log(users);
      var totalStepAllUser = 0;
      // console.log(totalStepAllUser);
      var totalWng = 0;
      for (let i in value) {
        const Obj = Object.keys(value[i])
          .filter((value) => value !== "isLoggedIn")
          .map((test) => {
            const individualUserStep =
              value[i][test]?.totalStep > 0 ? value[i][test]?.totalStep : 0;
            // console.log(
            //   value[i][test].name + " >> " + value[i][test].totalStep
            // );
            totalStepAllUser = totalStepAllUser + individualUserStep;
            // console.log(value[i][test].wng);
            const individualWng = 0;
            if (value[i][test].wng > 0) {
              totalWng = totalWng + value[i][test].wng;
              // console.log(value[i][test].wng);
            }
          });
      }
      // console.log("Total Step >> " + totalStepAllUser);
      // console.log(totalWng);
      var kcals = totalStepAllUser * 0.05;

      setSteps(totalStepAllUser);
      setUsers(users);
      setKcals(kcals);
      setWngs(totalWng);
    });


    // refRestaurant.once("value", (snapShot) => {
    //   const value = snapShot.val();
    //   // console.log(value);

    //   const imgRestaurantArray = [];
    //   for(let i in value){
    //     // console.log(i);
    //     console.log(value[i].img);
    //     imgRestaurantArray.push(value[i].img)

    //     // var imgRestaurant = value[i].img
    //   }
    //   console.log(imgRestaurantArray);
    //   setImgRestaurant(imgRestaurantArray);
    //   // var test = Object.keys(value).length;
    //   // console.log(a);

    //   // for (let i in value) {
    //   //   const Obj = Object.keys(value[i])
    //   //   .filter((value) => value !== "isLoggedIn")
    //   //   // console.log(Obj);
    //   //   .map((test) => {
    //   //     console.log(value[test]);
    //   //   })

    //   // }
    //   // setA(a);
    // });

  }, []);
  function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
    });

    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  }
  function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: " k" },
      { value: 1e6, symbol: " M" },
      { value: 1e10, symbol: " B" },
      { value: 1e12, symbol: " T" },
      { value: 1e15, symbol: " P" },
      { value: 1e18, symbol: " E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function (item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
      margin: theme.spacing(0.5),
      border: 0,
      '&.Mui-disabled': {
        border: 0,
      },
      '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
      },
      '&:first-of-type': {
        borderRadius: theme.shape.borderRadius,
      },
    },
  }));
  const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    // Override media queries injected by theme.mixins.toolbar
    '@media all': {
      minHeight: 128,
    },
  }));
  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1, background: "rgba(255,255,255,0.9)", color: "#53BB96" }}>
        <HideOnScroll {...props}>
          <AppBar sx={{ height: { xs: show ? "317px" : "70px", md: "100px" }, backgroundColor: "rgba(0,0,0,0.5)", boxShadow: 'none' }}>
            <Container maxWidth="xl">
              <Toolbar sx={{ display: { xs: 'contents', md: 'flex' } }} disableGutters>
                <Box sx={{ display: "flex", alignItems: "center", marginTop: 1 }}>
                  <Button>
                    <Avatar
                      alt="Walkwing"
                      src={'./images/walkwing.png'}
                      variant="square"
                      sx={{ height: "100%", width: { xs: '48px', md: 80 } }}
                    />
                  </Button>
                  <Box sx={{ flexGrow: { xs: 1, md: 1 }, }} />
                  <Box display={{ xs: "block", md: "none" }} onClick={toggleMenu}>
                    {show ? <CloseIcon color="primary" /> : <MenuIcon color="primary" />}
                  </Box>
                </Box>
                <Box sx={{ flexGrow: { xs: 1, md: 1 }, }} />

                <Box sx={{
                  display: { xs: show ? "block" : "none", md: "flex" },
                  flexBasis: { xs: "100%", md: "auto" },
                  marginTop: 2,
                }}>
                  <Stack
                    align="center"
                    justifyContent={{ xs: "center", md: "flex-end" }}
                    direction={{ xs: "column", md: "row" }}
                  >

                    <Button component={'a'} href="#walkwing-homepage" sx={{ color: "white" }} >
                      <Typography sx={{ fontSize: { xs: '1rem', md: '1rem' } }} variant="subtitle1" fontWeight={'bold'}>
                        หน้าหลัก
                      </Typography>
                    </Button>
                    <Button component="a" href={'#walkwing-whatwalkwing'} sx={{ color: "white" }} >
                      <Typography sx={{ fontSize: { xs: '1rem', md: '1rem' } }} variant="subtiltle1" fontWeight={'bold'}>
                        แนะนำ
                      </Typography>
                    </Button>
                    <Button component="a" href={'#walkwing-howwalkwing'} sx={{ color: "white" }} >
                      <Typography sx={{ fontSize: { xs: '1rem', md: '1rem' } }} variant="subtiltle1" fontWeight={'bold'}>
                        วิธีรับ Wing Point
                      </Typography>
                    </Button>
                    <Button component="a" href={'#walkwing-partner'} sx={{ color: "white" }} >
                      <Typography sx={{ fontSize: { xs: '1rem', md: '1rem' } }} variant="subtiltle1" fontWeight={'bold'}>
                        ร้านค้าที่เข้าร่วม
                      </Typography>
                    </Button>
                    <Button component='a' href={'#walkwing-faq'} sx={{ color: "white" }} >
                      <Typography sx={{ fontSize: { xs: '1rem', md: '1rem' } }} variant="subtiltle1" fontWeight={'bold'}>
                        คำถามที่พบบ่อย
                      </Typography>
                    </Button>
                    <Button component="a" href={'#walkwing-aboutme'} sx={{ color: "white" }} >
                      <Typography sx={{ fontSize: { xs: '1rem', md: '1rem' } }} variant="subtiltle1" fontWeight={'bold'}>
                        เกี่ยวกับเรา
                      </Typography>
                    </Button>
                  </Stack>
                </Box>
              </Toolbar>
            </Container>
          </AppBar>
        </HideOnScroll>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 1,
            },
            justifyContent: "center",
          }}
          id={'walkwing-homepage'}
        >
          <Grid container>
            <Grid item xs={12} sm={6} style={{ fontSize: 18, marginTop: 120, padding: '20px' }}>
              <center>
                <Typography variant="h6" component="div">
                  จำนวนก้าวทั้งหมด
                </Typography>
                {/* <Paper
                  elevation={0}
                  sx={{
                    display: 'flex',
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                    flexWrap: 'wrap',
                    bgcolor:'rgba()'
                  }}
                > */}
                <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={'center'} justifyContent={'center'} sx={{ width: '100%', marginBottom: '10px', marginTop: '10px', padding: '10px' }} >
                  {/* <Box>
                    <Button variant="outlined">
                      {wngs ? (Math.floor((wngs / 100000000000) % 10)).toFixed(0) : 0}
                    </Button>
                    <Button variant="outlined">
                      {wngs ? (Math.floor((wngs / 10000000000) % 10)).toFixed(0) : 0}
                    </Button>
                    <Button variant="outlined">
                      {wngs ? (Math.floor((wngs / 1000000000) % 10)).toFixed(0) : 0}
                    </Button>
                    <Button variant="outlined">
                      {wngs ? (Math.floor((wngs / 100000000) % 10)).toFixed(0) : 0}
                    </Button>
                    <Button variant="outlined">
                      {wngs ? (Math.floor((wngs / 10000000) % 10)).toFixed(0) : 0}
                    </Button>
                    <Button variant="outlined">
                      {wngs ? (Math.floor((wngs / 1000000) % 10)).toFixed(0) : 0}
                    </Button>
                    <Button variant="outlined">
                      {wngs ? (Math.floor((wngs / 100000) % 10)).toFixed(0) : 0}
                    </Button>
                    <Button variant="outlined">
                      {wngs ? (Math.floor((wngs / 10000) % 10)).toFixed(0) : 0}
                    </Button>
                    <Button variant="outlined">
                      {wngs ? (Math.floor((wngs / 1000) % 10)).toFixed(0) : 0}
                    </Button>
                    <Button variant="outlined">
                      {wngs ? (Math.floor((wngs / 100) % 10)).toFixed(0) : 0}
                    </Button>
                    <Button variant="outlined">
                      {wngs ? (Math.floor((wngs / 10) % 10)).toFixed(0) : 0}
                    </Button>
                    <Button variant="outlined">
                      {wngs ? (Math.floor(wngs % 10)).toFixed(0) : 0}
                    </Button>
                  </Box> */}
                  <ToggleButtonGroup
                    color="primary"
                    sx={{ color: '#82C481', borderColor: '#82C481' }}
                    exclusive
                  >

                    <ToggleButton sx={{ padding: '10px' }} aria-label="left aligned">
                      <Typography sx={{ color: '#82C481', borderColor: '#82C481' }}>
                        {wngs ? (Math.floor((wngs / 100000000000) % 10)).toFixed(0) : 0}
                      </Typography>
                    </ToggleButton>
                    <ToggleButton sx={{ padding: '10px' }} aria-label="left aligned">
                      <Typography sx={{ color: '#82C481', borderColor: '#82C481' }}>
                        {wngs ? (Math.floor((wngs / 10000000000) % 10)).toFixed(0) : 0}
                      </Typography>
                    </ToggleButton>
                    <ToggleButton sx={{ padding: '10px' }} aria-label="left aligned">
                      <Typography sx={{ color: '#82C481', borderColor: '#82C481' }}>
                        {wngs ? (Math.floor((wngs / 1000000000) % 10)).toFixed(0) : 0}
                      </Typography>
                    </ToggleButton>
                    <ToggleButton sx={{ padding: '10px' }} aria-label="left aligned">
                      <Typography sx={{ color: '#82C481', borderColor: '#82C481' }}>
                        {wngs ? (Math.floor((wngs / 100000000) % 10)).toFixed(0) : 0}
                      </Typography>
                    </ToggleButton>
                    <ToggleButton sx={{ padding: '10px' }} aria-label="left aligned">
                      <Typography sx={{ color: '#82C481', borderColor: '#82C481' }}>
                        {wngs ? (Math.floor((wngs / 10000000) % 10)).toFixed(0) : 0}
                      </Typography>
                    </ToggleButton>
                    <ToggleButton sx={{ padding: '10px' }} aria-label="left aligned">
                      <Typography sx={{ color: '#82C481', borderColor: '#82C481' }}>
                        {wngs ? (Math.floor((wngs / 1000000) % 10)).toFixed(0) : 0}
                      </Typography>
                    </ToggleButton>
                    <ToggleButton sx={{ padding: '10px' }} aria-label="left aligned">
                      <Typography sx={{ color: '#82C481', borderColor: '#82C481' }}>
                        {wngs ? (Math.floor((wngs / 100000) % 10)).toFixed(0) : 0}
                      </Typography>
                    </ToggleButton>
                    <ToggleButton sx={{ padding: '10px' }} aria-label="left aligned">
                      <Typography sx={{ color: '#82C481', borderColor: '#82C481' }}>
                        {wngs ? (Math.floor((wngs / 10000) % 10)).toFixed(0) : 0}
                      </Typography>
                    </ToggleButton>
                    <ToggleButton sx={{ padding: '10px' }} aria-label="left aligned">
                      <Typography sx={{ color: '#82C481', borderColor: '#82C481' }}>
                        {wngs ? (Math.floor((wngs / 1000) % 10)).toFixed(0) : 0}
                      </Typography>
                    </ToggleButton>
                    <ToggleButton sx={{ padding: '10px' }} aria-label="left aligned">
                      <Typography sx={{ color: '#82C481', borderColor: '#82C481' }}>
                        {wngs ? (Math.floor((wngs / 100) % 10)).toFixed(0) : 0}
                      </Typography>
                    </ToggleButton>
                    <ToggleButton sx={{ padding: '10px' }} aria-label="left aligned">
                      <Typography sx={{ color: '#82C481', borderColor: '#82C481' }}>
                        {wngs ? (Math.floor((wngs / 10) % 10)).toFixed(0) : 0}
                      </Typography>
                    </ToggleButton>
                    <ToggleButton sx={{ padding: '10px' }} aria-label="left aligned">
                      <Typography sx={{ color: '#82C481', borderColor: '#82C481' }}>
                        {wngs ? (Math.floor(wngs % 10)).toFixed(0) : 0}
                      </Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <Typography align={'center'} variant="h5" marginLeft={'5px'} sx={{ fontSize: '1.25rem !impoRtant' }} component={'div'}>ก้าว</Typography>
                </Stack>
                {/* </Paper> */}
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", marginBottom: 2, background: ' "linear-gradient(to right, #82C481 0%, #52BA95 100%)"' }}
                >
                  ทุกก้าวของคุณ
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", marginBottom: 4 }}
                >
                  จะไม่ไร้ประโยชน์อีกต่อไป
                </Typography>
                <Grid container justifyContent="center" spacing="20">
                  <Grid item xs={12} sm={12}>
                    <Avatar
                      alt="Remy Sharp"
                      src={'./images/walkwing.png'}
                      variant="square"
                      style={{ height: "100%", width: 350 }}
                    />

                  </Grid>
                  <Grid item>
                    <Button component="a" href='https://play.google.com/store/apps/details?id=com.walkwing.app'>
                      <Avatar
                        alt="Remy Sharp"
                        src={'./images/android.png'}
                        variant="square"
                        style={{ height: 71, width: "100%" }}
                      />
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button component="a" href="https://apps.apple.com/th/app/walkwing/id1580362898?l=th">
                      <Avatar
                        alt="Remy Sharp"
                        src={'./images/ios.png'}
                        variant="square"
                        style={{ height: 70, width: "100%" }}
                      />
                    </Button>
                  </Grid>
                </Grid>
              </center>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              style={{ fontSize: 18, marginTop: 100, padding: 20 }}
            >
              <center>
                <Avatar
                  alt="tester"
                  src={'./images/step03-01-1.png'}
                  variant="square"
                  style={{ height: "100%", width: 345, marginTop: 25 }}
                />
              </center>
            </Grid>
            {/* <Grid items sm={12}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: 7,
                }}
              >
                สถิติโดยรวม
              </Typography>
              <Box sx={{ width: "100%", padding: 3, color: 'white' }}>
                <Stack direction={'row'} justifyContent={'center'} spacing={5}>
                  <Card sx={{ display: 'flex', width: '425px', height: '175px', borderRadius: '15px', backgroundColor: '#EB92BE' }}>
                    <Box sx={{ width: 200, height: '100%', padding: '20px' }}>
                      <Avatar sx={{ width: 200, height: '100%', backgroundColor: 'rgba(255,255,255,0)' }} variant="rounded">
                        <DirectionsRunIcon sx={{ fontSize: '150px' }} />
                      </Avatar>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '10px' }}>
                      <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography variant="h6" color="text.secondary" component="div">
                          ผู้ใช้ทั้งหมด
                        </Typography>
                        <Box sx={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                          marginBottom: '-25px'
                        }}>

                          <Typography align="center" sx={{ fontWeight: 'bold' }} component="div" variant="h4">
                            {nFormatter(users, 2)}
                          </Typography>
                          <Typography align="center" component="div" variant="5">
                            Users
                          </Typography>
                        </Box>
                      </CardContent>

                    </Box>

                  </Card>
                  <Card sx={{ display: 'flex', width: '425px', height: '175px', borderRadius: '15px', backgroundColor: '#A8E7E9' }}>
                    <CardMedia
                      component="img"
                      sx={{ width: 200, padding: '20px' }}
                      image={step}
                      alt="Live from space album cover"
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography variant="h6" color="text.secondary" component="div">
                          จำนวนก้าวทั้งหมด
                        </Typography>

                        <Box sx={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                          marginBottom: '-25px'
                        }}>

                          <Typography align="center" sx={{ fontWeight: 'bold' }} component="div" variant="h4">
                            {nFormatter(steps, 2)}
                          </Typography>
                          <Typography align="center" component="div" variant="5">
                            Steps
                          </Typography>
                        </Box>
                      </CardContent>

                    </Box>

                  </Card>
                </Stack>
                <Stack marginTop={5} direction={'row'} justifyContent={'center'} spacing={5}>
                  <Card sx={{ display: 'flex', width: '425px', height: '175px', borderRadius: '15px', backgroundColor: '#FFEF78' }}>
                    <Box sx={{ width: 200, height: '100%', padding: '20px' }}>
                      <Avatar sx={{ width: 200, height: '100%', backgroundColor: 'rgba(255,255,255,0)' }} variant="rounded">
                        <FitnessCenterIcon sx={{ color: "#52BA95", fontSize: '150px' }} />
                      </Avatar>
                    </Box>
                   
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography variant="h6" color="text.secondary" component="div">
                          จำนวนแคลอรี่ทั้งหมด
                        </Typography>
                        <Box sx={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                          marginBottom: '-25px'
                        }}>

                          <Typography align="center" sx={{ fontWeight: 'bold' }} component="div" variant="h4">
                            {nFormatter(kcals, 2)}
                          </Typography>
                          <Typography align="center" component="div" variant="5">
                            Kcals
                          </Typography>
                        </Box>
                      </CardContent>

                    </Box>

                  </Card>
                  <Card sx={{ display: 'flex', width: '425px', height: '175px', borderRadius: '15px', backgroundColor: '#B4FE98' }}>
                    <CardMedia
                      component="img"
                      sx={{ width: 200, padding: '20px' }}
                      image={walkwing}
                      alt="Live from space album cover"
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography variant="h6" color="text.secondary" component="div">
                          จำนวนเหรียญทั้งหมด
                        </Typography>
                        <Box sx={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                          marginBottom: '-25px'
                        }}>

                          <Typography align="center" sx={{ fontWeight: 'bold' }} component="div" variant="h4">
                            {nFormatter(wngs, 2)}
                          </Typography>
                          <Typography align="center" component="div" variant="5">
                            WNG
                          </Typography>
                        </Box>
                      </CardContent>

                    </Box>

                  </Card>
                </Stack>
                
              </Box>

            </Grid> */}
          </Grid>
        </Box>

      </Box>
    </React.Fragment >
  );
}
