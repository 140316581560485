import React, { Fragment, useEffect } from "react";
import Home from "./pages/Home";
import HowItWorks from "./pages/HowItWorks";
import Partners from "./pages/Partners";
import Questions from "./pages/Questions";
import AboutMe from "./pages/AboutMe";
import { useAuth } from "./context/AuthContext";
import firebase from "../src/configs/firebase";
const App = () => {
  const { login, currentUser } = useAuth();
  const emailFirebase = process.env.REACT_APP_FIREBASE_EMAIL
  const passwordFirebase = process.env.REACT_APP_FIREBASE_PASSWORD
  useEffect(async () => {
    try {

      if (!currentUser) {
        await login(emailFirebase, passwordFirebase);
        await firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            localStorage.setItem("login", "yes");
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, [currentUser, emailFirebase, login, passwordFirebase])
  console.log(localStorage.getItem("login"), currentUser?.uid);
  return (
    <Fragment>
      <div>
        <Home />
        <HowItWorks />
        <Partners />
        <Questions />
        <AboutMe />
      </div>
    </Fragment>
  );
};

export default App;
