import * as React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'

function createData(questions, answer) {
  return { questions, answer };
}

const rows = [
  createData("ฉันจะสามารถดาวน์โหลดแอปได้อย่างไร", `คุณสามารถไปที่ "PlayStore" สำหรับผู้ใช้ Android และ "AppStore" สำหรับผู้ใช้ IOS จากนั้นค้นหา "WalkWing" ดาวน์โหลด`),
  createData("ถ้าหากไม่เข้ามาในแอปพลิเคชันเพื่ออัปเดต Wing Point จะหายหรือไม่", `ขึ้นอยู่กับประเภทของระบบปฏิบัติการของโทรศัพท์หากเป้นใน IOS ถ้าหากไม่เข้าไปอัปเดตจะไม่ได้รับ Point ส่วน Andriod จะสามารถอัปเดต Point ได้ในวันต่อไป `),

];

export default function ButtonAppBar() {
  return (
    <React.Fragment>
      <Box
        id={'walkwing-faq'}
        sx={{ flexGrow: 1, background: "rgba(255,255,255,1)", color: "#53BB96", padding: { xs: 2, md: 20 } }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 1,
            },
            justifyContent: "center",
          }}
        >
          <Box sx={{ justifyContent: "center", display: "flex" }}>
            <Typography
              variant="h3"

              sx={{ fontWeight: "bold", marginBottom: 5 }}
            >
              คําถามที่พบบ่อย (FAQ)
            </Typography>
          </Box>
          <Box sx={{ width: '100%', flexGrow: 1 }}>
            {
              rows?.map((row, index) => (
                <Accordion sx={{ borderRadius: '5px', color: 'white' }} >
                  <AccordionSummary
                    sx={{ background: "#71B37a" }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography> Q: {row?.questions}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography color={'black'}>
                      Ans:  {row?.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>))
            }
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
