import * as React from "react";
// import Slider from "react-slick";
import { Typography, Avatar, Button, Box, Grid } from "@mui/material";
import "./slick.css";
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
export default function ButtonAppBar() {
  // function SampleNextArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{ ...style, display: "block", color: "blue" }}
  //       onClick={onClick}
  //     />
  //   );
  // }

  // function SamplePrevArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{ ...style, display: "block", color: "red !important" }}
  //       onClick={onClick}
  //     />
  //   );
  // }
  // const settings = {
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 3,
  //   nextArrow: <SampleNextArrow />,
  //   prevArrow: <SamplePrevArrow />,
  // };

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1, background: "rgba(0,0,0,0.9)", color: "#53BB96" }}>
        <Box style={{ height: 750, padding: { xs: 5, md: 50 } }}>
          <Box sx={{ justifyContent: "center", display: "flex" }}>
            <Typography id="walkwing-aboutme" variant="h4" sx={{ fontWeight: "bold", marginTop: 20 }}>
              เกี่ยวกับเรา
            </Typography>
          </Box>
          <Grid container sx={{ padding: 6 }}>
            <Grid item xs={6} md={6} textAlign={{ xs: "left", md: 'left' }}>
              <Typography sx={{ fontWeight: "bold" }}>WALKWING</Typography>
              <Typography >
                <Button sx={{ fontSize: { xs: '12px', md: '16px' } }} component="a" href="#walkwing-homepage" color="inherit">หน้าหลัก</Button>
              </Typography>
              <Typography >
                <Button sx={{ display: 'flex', justifyContent: 'flex-start', fontSize: { xs: '12px', md: '16px' } }} component="a" href="#walkwing-whatwalkwing" color="inherit">แนะนำ</Button>
              </Typography>
              <Typography>
                <Button sx={{ fontSize: { xs: '12px', md: '16px' } }} component="a" href="#walkwing-howwalkwing" color="inherit">วิธีรับ Wing Point</Button>
              </Typography>
              <Typography>
                <Button sx={{ fontSize: { xs: '12px', md: '16px' } }} component="a" href="#walkwing-partner" color="inherit">ร้านค้าที่เข้าร่วม</Button>
              </Typography>
              <Typography>
                <Button sx={{ fontSize: { xs: '12px', md: '16px' } }} component="a" href="#walkwing-faq" color="inherit">คำถามที่พบบ่อย</Button>
              </Typography>
              <Typography>
                <Button sx={{ fontSize: { xs: '12px', md: '16px' } }} component="a" href="#walkwing-aboutme" color="inherit">เกี่ยวกับเรา</Button>
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} textAlign={{ xs: "left", md: 'left' }}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>ติดต่อเรา</Typography>
              <Button sx={{ width: { xs: '100%', md: '50%' } }} component='a' href='https://web.facebook.com/walkwing' color="inherit">
                <Grid container>
                  <Grid item xs={3} md={2}>
                    <Avatar
                      alt="streamsouth"
                      variant="square"
                      // src={streamsouth}
                      style={{ height: 22, width: 22, backgroundColor: '#548CFF' }}
                      sx={{ marginRight: 2 }}
                    >
                      <FacebookIcon />
                    </Avatar>
                  </Grid>
                  <Grid sx={{ fontSize: { xs: '12px', md: '16px' } }} item xs={9} md={10}>walkwing</Grid>
                </Grid>
              </Button>
              <Typography>
                <Button sx={{ width: { xs: '100%', md: '50%' } }} component='a' href='https://web.facebook.com/walkwing' color="inherit">
                  <Grid container>
                    <Grid item xs={3} md={2}>
                      <Avatar
                        alt="streamsouth"
                        variant="square"
                        style={{ height: 22, width: 22, backgroundColor: '#D77FA1' }}
                        sx={{ marginRight: 2 }}
                      >
                        <EmailIcon />
                      </Avatar>
                    </Grid>
                    <Grid sx={{ fontSize: { xs: '12px', md: '16px' } }} item xs={9} md={10}>admin@walkwing.co</Grid>
                  </Grid>
                </Button>
              </Typography>
              <Typography>
                <Button sx={{ width: { xs: '100%', md: '50%' } }} component='a' href='https://streamsouth.tech/' color="inherit">
                  <Grid container>
                    <Grid item xs={3} md={2}>
                      <Avatar
                        alt="streamsouth"
                        variant="square"
                        src={'./images/streamsouth.PNG'}
                        style={{ height: 22, width: 22 }}
                        sx={{ marginRight: 2 }}
                      />
                    </Grid>
                    <Grid sx={{ fontSize: { xs: '12px', md: '16px' } }} xs={9} md={10} item>Stream South Technology</Grid>
                  </Grid>
                </Button>
              </Typography>

              <Typography>
                <Button sx={{ width: { xs: '100%', md: '50%' } }} component='a' href='https://goo.gl/maps/yi6sacNTBRD8xecD6' color="inherit">
                  <Grid container>
                    <Grid item xs={3} md={2}>
                      <Avatar
                        alt="streamsouth"
                        variant="square"
                        style={{ height: 22, width: 22, backgroundColor: '#53BB96' }}
                        sx={{ marginRight: 2 }}
                      >
                        <LocationOnIcon />
                      </Avatar>
                    </Grid>
                    <Grid sx={{ fontSize: { xs: '12px', md: '16px' } }} item xs={9} md={10}>บริษัท สตรีมเซาท์เทคโนโลยี จำกัด เลขที่ 269 ถ.แสงจันทร์ ต.หาดใหญ่ อ.หาดใหญ่ จ.สงขลา 90110.</Grid>
                  </Grid>
                </Button>
              </Typography>
            </Grid>
          </Grid>
          {/* <Typography
            variant="h6"
            sx={{ textAlign: "center", marginTop: 20, marginBottom: 10 }}
          >
            ร้านค้าที่เข้าร่วมรายการ
          </Typography> */}
          {/* <Box sx={{ textAlign: "center", marginLeft: 50, marginRight: 50 }}>
            <Slider {...settings}>
              <Box>
                <Button>
                  <Avatar alt="Remy Sharp" style={{ height: 90, width: 90 }} />
                </Button>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  ร้าน1
                </Typography>
              </Box>

              <Box>
                <Button>
                  <Avatar alt="Remy Sharp" style={{ height: 90, width: 90 }} />
                </Button>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  ร้าน2
                </Typography>
              </Box>
              <Box>
                <Button>
                  <Avatar alt="Remy Sharp" style={{ height: 90, width: 90 }} />
                </Button>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  ร้าน3
                </Typography>
              </Box>
              <Box>
                <Button>
                  <Avatar alt="Remy Sharp" style={{ height: 90, width: 90 }} />
                </Button>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  ร้าน4
                </Typography>
              </Box>
              <Box>
                <Button>
                  <Avatar alt="Remy Sharp" style={{ height: 90, width: 90 }} />
                </Button>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  ร้าน5
                </Typography>
              </Box>
              <Box>
                <Button>
                  <Avatar alt="Remy Sharp" style={{ height: 90, width: 90 }} />
                </Button>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  ร้าน6
                </Typography>
              </Box>
            </Slider>
          </Box> */}
        </Box>
      </Box>
    </React.Fragment>
  );
}
