import { CssBaseline } from "@mui/material";
import { deepOrange, green } from "@mui/material/colors";
import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import reportWebVitals from "./reportWebVitals";
const themeCreacte = createTheme({
  palette: {
    primary: {
      main: green[500],
    },
    secondary: {
      main: deepOrange[500],
    },
  },
  typography: {
    button: {
      textTransform: 'none'
    }

  }
});
const theme = responsiveFontSizes(themeCreacte)
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <CssBaseline />
        <App />
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
